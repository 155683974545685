:root {
  --navy: #000000;
  --lightest-navy: #ffffff;
  --light-navy: #ffffff;
  --green: #fff764;
  --light-green: rgba(255, 229, 100, 0.1);
  --Yankees-Blue: #403611;
  --light-Yankees-Blue: #d1c6a8;
  --navy-shadow: rgba(27, 21, 2, 0.7);
  --lato: "Lato", sans-serif;
  --ptfont: "Georgia", sans-serif;
  --filter: none;
}
::selection {
  /* background-color: #233554; */
  background-color: var(--lightest-navy);
  color: var(--navy);
}
::-webkit-scrollbar {
  width: 5px;
  background: var(--navy);
}

::-webkit-scrollbar-thumb {
  background: var(--light-navy);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
.page-load {
  width: 100%;
  height: 100vh;
  background-color: var(--navy);
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background-color: var(--navy);
  color: var(--light-navy);
  overflow-x: hidden;
  transition: 0.6s background-color ease;
}

body.light {
  --navy: #eeeeee;
  --lightest-navy: #222831;
  --light-navy: #393e46;
  --green: black;
  --Yankees-Blue: white;
  --light-Yankees-Blue: rgb(52, 42, 42);
  --navy-shadow: #393e46;
  --filter: invert(1);
}
body.res-nav-active {
  overflow: hidden;
}
/* body.res-nav-active nav {
  filter: blur(5px);
} */
body.res-nav-active #profile {
  filter: blur(5px);
}
body.res-nav-active #projects {
  filter: blur(5px);
}
body.res-nav-active #contact {
  filter: blur(5px);
}
.none {
  display: none;
}
