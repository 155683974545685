/* Styling for .contactme section */
.contactme {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactme .contactme-title h2 {
  font-size: 60px;
  color: var(--green);
  padding: 0;
  margin: 0;
}

.contactme .contactme-desc p {
  font-size: 20px;
  white-space: pre-wrap;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  line-height: 30px;
  padding: 15px 20%;
  text-align: center;
}

.contactme button {
  border: 1px solid var(--green);
  color: var(--lightest-navy);
  color: black;
  width: 190px;
  height: 50px;
  border-radius: 10px;
  font-family: var(--lato);
  font-size: 17px;
  cursor: pointer;
  transition: 0.5s ease all;
}


@media only screen and (max-width: 460px) {
  .contactme {
    height: 70vh;
  }
  .contactme .contactme-title h2 {
    font-size: 50px;
  }
  .contactme .contactme-desc p {
    font-size: 15px;
  }
  .contactme button {
    height: 40px;
    width: 140px;
  }
}

/* Animation for .thank class */
.thank {
  margin-top: 80px;
  font-family: cursive;
  animation: slideIn 1s ease forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
