/* CSS for Achieve component */
.achieve {
    padding: 13px; /* Adjust padding as needed */
    margin-bottom: 70px;
}
.acheive-title{
    animation: profiletext 2000ms;
    text-align: center;
    font-size: 2.0rem;
    color: var(--green);
}

.contactme-title h2 {
    color: var(--green);
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem; /* Title font size */
    color: #333; /* Title color */
    margin-bottom: 20px; /* Space below title */
}

.contactme-desc {
    max-width: 75%; /* Limit content width */
    margin: 0 auto; /* Center content horizontally */
    padding: 0 20px; /* Padding around content */
    line-height: 1.6; /* Line height for paragraphs */
    font-family: cursive;
    animation: slideIn 1s ease forwards;
}

.contactme-desc p {
    margin-bottom: 15px; /* Space between paragraphs */
    text-align: left; /* Align text left */
    animation: profiletext 2000ms;
}

.profile-text-about {
    font-style: italic; /* Italicize profile text */
    margin-top: 20px; /* Space above italicized text */
    animation: profiletext 2000ms;
}
