.page-load {
  width: 99%;
  height: 97vh;
  background-color: black;
 margin: 0;
  padding: 0;
  /* background-image: url('../../img/bg.png'); */
  /* background-size: cover;  */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed;  */
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-load h1 {
  font-size: 50px;
  font-family: var(--lato);
}
.fade {
  opacity: 0;
  display: none;
}
